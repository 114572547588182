import * as React from 'react';
import ProjectLayout from '../components/project-layout';

const WeatherMainPage = ({ location }) => {
  return (
    <ProjectLayout pageTitle="Climate change over time" location={location}>
      <div>
        Hi, welcome to my project on weather predictions using a time series analysis! Click on the introduction to get
        started.
      </div>
    </ProjectLayout>
  );
};

export default WeatherMainPage;
