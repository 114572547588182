import * as React from 'react';
import ProjectLayout from '../components/project-layout';

const FoodMainPage = ({ location }) => {
  return (
    <ProjectLayout pageTitle="Covid19's Effect on the Food Supply" location={location}>
      <div>
        Hi, welcome to my project on the impact of Covid19 on the food supply! Click on the introduction to get started.
      </div>
    </ProjectLayout>
  );
};

export default FoodMainPage;
