import * as React from 'react';
import LineChart from './line-chart.js';
import { graphql, useStaticQuery } from 'gatsby';

const ChartFactory = ({ country, labels, map }) => {
  const data = useStaticQuery(graphql`
    query {
      allAustraliaCovidData {
        nodes {
          data {
            Confirmed
            Date
          }
        }
      }
      allUsCsv {
        nodes {
          Confirmed
          Date
        }
      }
    }
  `);

  return <LineChart data={map.data(data, country)} labels={labels} map={map} />;
};

export default ChartFactory;
