import React, { useState } from 'react';
import {
  Flex,
  chakra,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Center
} from '@chakra-ui/react';
import { DownloadIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { FaCode, FaExpand } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

const DataVisualizer = ({ id, graphUrl, codeUrl, dataUrl, children, height, responsive }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const spinnerId = uuidv4();
  const [spinner, setSpinner] = useState(0);
  const [modalSpinner, setModalSpinner] = useState(1);

  const renderDataUrl = () => {
    if (!dataUrl) {
      return <></>;
    } else if (typeof dataUrl === 'string') {
      return (
        <chakra.a
          href={dataUrl}
          cursor={'pointer'}
          _hover={{
            color: 'blue'
          }}
        >
          Download the Data
          <DownloadIcon ml={'10px'} mb={'2px'} />
        </chakra.a>
      );
    } else {
      return (
        <Menu placement="bottom-end">
          <MenuButton
            as={Button}
            color="black"
            variant="link"
            fontWeight="normal"
            _hover={{
              color: 'blue'
            }}
            rightIcon={<ChevronDownIcon />}
          >
            Download the Data
          </MenuButton>
          <MenuList>
            {Object.keys(dataUrl).map((key) => {
              return (
                <MenuItem key={key}>
                  <chakra.a
                    href={dataUrl[key]}
                    cursor={'pointer'}
                    _hover={{
                      color: 'blue'
                    }}
                    display={'flex'}
                    justifyContent={'space-between'}
                    width={'100%'}
                  >
                    {key}
                    <DownloadIcon mt={'2px'} />
                  </chakra.a>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      );
    }
  };

  return (
    <>
      <Flex justifyContent="space-between">
        {!codeUrl ? (
          <></>
        ) : (
          <chakra.a
            href={codeUrl}
            display="flex"
            cursor={'pointer'}
            _hover={{
              color: 'blue'
            }}
            target={'_blank'}
          >
            See the code
            <chakra.span ml={'10px'} mt={'5px'}>
              <FaCode />
            </chakra.span>
          </chakra.a>
        )}
        {renderDataUrl()}
      </Flex>
      {spinner ? (
        <chakra.a
          onClick={onOpen}
          display="flex"
          float="right"
          cursor={'pointer'}
          _hover={{
            color: 'blue'
          }}
        >
          <chakra.span ml={'10px'} mt={'20px'}>
            <FaExpand />
          </chakra.span>
        </chakra.a>
      ) : (
        <></>
      )}
      {!!graphUrl ? (
        <>
          {!spinner ? (
            <div id={spinnerId + '-spinner'}>
              <Center m="10px">
                Loading...&nbsp;
                <Spinner size="sm" />
              </Center>
            </div>
          ) : (
            <></>
          )}

          {responsive ? (
            <chakra.div position="relative" paddingBottom={height} paddingTop="25px" marginRight="15px" height="0">
              <iframe
                id={spinnerId}
                title={id}
                style={{
                  border: 'none',
                  margin: '0 auto',
                  padding: '20px',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%'
                }}
                seamless="seamless"
                src={graphUrl}
                onLoad={() => setSpinner(true)}
                loading="lazy"
              ></iframe>
            </chakra.div>
          ) : (
            <iframe
              id={spinnerId}
              title={id}
              scrolling="no"
              style={{ border: 'none', width: 'calc(100% - 10vw)', margin: '0 auto', padding: '20px 0px' }}
              seamless="seamless"
              src={graphUrl}
              height={height ? height : '700'}
              onLoad={() => setSpinner(true)}
              loading="lazy"
            ></iframe>
          )}

          {!!children ? (
            <Center
              style={{ color: 'black', fontSize: '14px', margin: '0 50px', fontStyle: 'italic', textAlign: 'center' }}
            >
              {children}
            </Center>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div>No visualization exists for this data set yet. Please check back later.</div>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent minWidth={'85vw'} minHeight={'85vh'}>
          <ModalCloseButton />
          <ModalBody>
            {modalSpinner ? (
              <div id={spinnerId + '-modal-spinner'}>
                <Center m="10px">
                  Loading...&nbsp;
                  <Spinner size="sm" />
                </Center>
              </div>
            ) : (
              <></>
            )}
            <iframe
              id={spinnerId + '-modal'}
              title={id}
              style={{
                border: 'none',
                margin: '0 auto',
                padding: '25px 0px',
                position: 'absolute',
                top: 25,
                left: 0,
                bottom: 5,
                right: 0,
                width: '98%',
                height: '98%'
              }}
              onLoad={() => setModalSpinner(false)}
              loading="lazy"
              seamless="seamless"
              src={graphUrl}
            ></iframe>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DataVisualizer;
