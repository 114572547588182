import ImageVisualizer from "../../../../src/components/image-visualizer.js";
import DataVisualizer from "../../../../src/components/data-visualizer.js";
import TabbedDataVisualizer from "../../../../src/components/tabbed-data-visualizer.js";
import TableFactory from "../../../../src/components/tables/table-factory.js";
import * as React from 'react';
export default {
  ImageVisualizer,
  DataVisualizer,
  TabbedDataVisualizer,
  TableFactory,
  React
};