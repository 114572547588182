import * as React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { chakra } from '@chakra-ui/react';
import styled from 'styled-components';

const BreadcrumbLink = styled((props) => <Link {...props} />)`
  margin: 0.5px 2px 0px 2px !important;
  padding: 4px 0px 4px 0px;
  text-decoration: none;
  color: #4a5568;
  :hover {
    color: #3182ce;
    cursor: pointer;
  }
`;

const createBreadcrumbMap = (data) => {
  const sortedRoutes = data.allMdx.nodes.sort((a, b) => a.slug.length - b.slug.length);
  const breadcrumbMap = {};

  sortedRoutes.forEach((node) => {
    let builtRoute = '';
    let nextRoute = '';
    let builtRouteName = [
      <BreadcrumbLink to="/" key="/home">
        Home
      </BreadcrumbLink>
    ];

    const slug = node.slug;
    const title = node.frontmatter.title;
    const routes = slug.split('/');
    for (let i = 0; i < routes.length - 1; i++) {
      builtRoute += `/${routes[i]}`;
      nextRoute = builtRoute.slice(1);
      if (!breadcrumbMap[nextRoute]) {
        const nextLinkName = routes[i].charAt(0).toUpperCase() + routes[i].slice(1);
        builtRouteName.push(
          <span key={builtRoute}>
            <span>&nbsp;>&nbsp;</span>
            <BreadcrumbLink to={`${builtRoute}/`}>{nextLinkName}</BreadcrumbLink>
          </span>
        );
        breadcrumbMap[nextRoute] = {
          name: builtRouteName,
          route: builtRoute
        };
      } else {
        builtRouteName = breadcrumbMap[nextRoute].name;
      }
    }
    breadcrumbMap[slug] = {
      name: [
        ...builtRouteName,
        <span key={`/${slug}`}>
          <span>&nbsp;>&nbsp;</span>
          <BreadcrumbLink to={`/${slug}/`}>{title}</BreadcrumbLink>
        </span>
      ],
      route: `/${slug}/`
    };
  });
  return breadcrumbMap;
};

const ProjectBreadcrumb = ({ location }) => {
  let path = location.pathname;
  if (path.startsWith('/')) {
    path = path.slice(1);
  }
  if (path.endsWith('/')) {
    path = path.substring(0, path.length - 1);
  }
  const data = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          frontmatter {
            title
          }
          slug
        }
      }
    }
  `);
  const breadcrumbMap = createBreadcrumbMap(data);
  const breadcrumb = !!breadcrumbMap[path] ? breadcrumbMap[path] : { name: <></> };
  return <chakra.div mb="10px">{breadcrumb.name}</chakra.div>;
};

export default ProjectBreadcrumb;
