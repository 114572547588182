import React from 'react';
import { Flex, chakra, Menu, MenuButton, MenuList, MenuItem, Button, Center } from '@chakra-ui/react';
import { DownloadIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { FaCode } from 'react-icons/fa';
import ResponsiveImage from './responsive-image';

const ImageVisualizer = ({ src, alt, dataUrl, codeUrl, width, children }) => {
  const renderDataUrl = () => {
    if (!dataUrl) {
      return <></>;
    } else if (typeof dataUrl === 'string') {
      return (
        <chakra.a
          href={dataUrl}
          cursor={'pointer'}
          _hover={{
            color: 'blue'
          }}
        >
          Download the Data
          <DownloadIcon ml={'10px'} mb={'2px'} />
        </chakra.a>
      );
    } else {
      return (
        <Menu placement="bottom-end">
          <MenuButton
            as={Button}
            color="black"
            variant="link"
            fontWeight="normal"
            _hover={{
              color: 'blue'
            }}
            rightIcon={<ChevronDownIcon />}
          >
            Download the Data
          </MenuButton>
          <MenuList>
            {Object.keys(dataUrl).map((key) => {
              return (
                <MenuItem key={key}>
                  <chakra.a
                    href={dataUrl[key]}
                    cursor={'pointer'}
                    _hover={{
                      color: 'blue'
                    }}
                    display={'flex'}
                    justifyContent={'space-between'}
                    width={'100%'}
                  >
                    {key}
                    <DownloadIcon mt={'2px'} />
                  </chakra.a>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      );
    }
  };

  return (
    <>
      <Flex justifyContent="space-between">
        {!codeUrl ? (
          <></>
        ) : (
          <chakra.a
            href={codeUrl}
            display="flex"
            cursor={'pointer'}
            _hover={{
              color: 'blue'
            }}
            target={'_blank'}
          >
            See the code
            <chakra.span ml={'10px'} mt={'5px'}>
              <FaCode />
            </chakra.span>
          </chakra.a>
        )}
        {renderDataUrl()}
      </Flex>
      <ResponsiveImage src={src} alt={alt} width={width} />
      {!!children ? (
        <Center
          style={{ color: 'black', fontSize: '14px', margin: '0 50px', fontStyle: 'italic', textAlign: 'center' }}
          dangerouslySetInnerHTML={{ __html: children }}
        ></Center>
      ) : (
        <></>
      )}
    </>
  );
};

export default ImageVisualizer;
