import * as React from 'react';
import ProjectLayout from '../components/project-layout';

const ContractsMainPage = ({ location }) => {
  return (
    <ProjectLayout pageTitle="Cyber Risk Management and Prioritization" location={location}>
      <div>
        Hi, welcome to my project on exporing United States contract data using modern visualization techniques. Click
        on Introduction to get started.
      </div>
    </ProjectLayout>
  );
};

export default ContractsMainPage;
