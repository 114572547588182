import React from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import DataVisualizer from './data-visualizer';
import ImageVisualizer from './image-visualizer';

/**
 *
 * @param {data} data Array[Tab or {
 *      isSeparateTab: boolean
 *      childTabs: Array[Tab]
 *      tabName: string
 * }]
 *
 * Tab {
 *      downloadDataLink: string,
 *      visualizationLink: string,
 *      visualizationAltText: string,
 *      visualizationId: string,
 *      visualizationWidth: string,
 *      codeLink: string,
 *      tabName: string
 * }
 * @returns
 */

const TabbedDataVisualizer = ({ data }) => {
  const visualizeData = (item) => {
    if (item.visualizationLink && item.visualizationLink.toString().endsWith('.html')) {
      return (
        <DataVisualizer
          id={item.visualizationId}
          graphUrl={item.visualizationLink}
          dataUrl={item.downloadDataLink}
          codeUrl={item.codeLink}
          responsive={item.responsive}
          height={item.visualizationHeight}
        >
          {item.caption}
        </DataVisualizer>
      );
    } else {
      return (
        <ImageVisualizer
          src={item.visualizationLink}
          alt={item.visualizationAltText}
          width={item.visualizationWidth}
          dataUrl={item.downloadDataLink}
          codeUrl={item.codeLink}
        >
          {item.caption}
        </ImageVisualizer>
      );
    }
  };

  return (
    <>
      <Tabs isFitted variant="enclosed" mt={'20px'} mb={'20px'}>
        <TabList mb="1em">
          {data.map((item, index) => {
            return <Tab key={index}>{item.tabName}</Tab>;
          })}
        </TabList>
        <TabPanels>
          {data.map((item, index) => {
            return item.isSeparateTab ? (
              <TabPanel key={index}>
                <TabbedDataVisualizer data={item.childTabs}></TabbedDataVisualizer>
              </TabPanel>
            ) : (
              <TabPanel key={index}>{visualizeData(item)}</TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </>
  );
};

export default TabbedDataVisualizer;
