import { Center, List, ListItem, ListIcon, Spacer } from '@chakra-ui/react';
import { CheckCircle } from '@chakra-ui/icons';
import * as React from 'react';
export default {
  Center,
  List,
  ListItem,
  ListIcon,
  Spacer,
  CheckCircle,
  React
};