import * as React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Flex,
  chakra,
  Center
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import { graphql, useStaticQuery } from 'gatsby';
import GenericTable from './generic-table.js';

const TableFactory = ({ map, columns, title, isOpen, children }) => {
  const data = useStaticQuery(graphql`
    query {
      allAustraliaCovidData {
        nodes {
          data {
            Confirmed
            Date
          }
          url
        }
      }
      allSearchResultsCalculatedKMeans {
        nodes {
          data {
            covid
            drought
            locusts
            ebola
            Cluster
          }
          url
        }
      }
      allSearchResultsHierarchical {
        nodes {
          data {
            covid
            drought
            locusts
            ebola
            Cluster
          }
          url
        }
      }
      allSearchResultsDensity {
        nodes {
          data {
            covid
            drought
            locusts
            ebola
            Cluster
          }
          url
        }
      }
      allLockdownResultsKMeans {
        nodes {
          data {
            Cluster
            TotalInLockdown
            TotalNotInLockdown
          }
          url
        }
      }
      allLockdownResultsDensity {
        nodes {
          data {
            Cluster
            TotalInLockdown
            TotalNotInLockdown
          }
          url
        }
      }
      allCombinedSearchData {
        nodes {
          data {
            link
            title
            topic
            text
          }
          url
        }
      }
      allArmSupportRules {
        nodes {
          data {
            LHS
            RHS
            support
            confidence
            lift
          }
          url
        }
      }
      allArmConfidenceRules {
        nodes {
          data {
            LHS
            RHS
            support
            confidence
            lift
          }
          url
        }
      }
      allArmLiftRules {
        nodes {
          data {
            LHS
            RHS
            support
            confidence
            lift
          }
          url
        }
      }
      allRawDtInfo {
        nodes {
          data {
            Label
            Enough
            LackVariety
            Sometimes
            NotEnough
            Region
          }
        }
      }
      allRawNbInfo {
        nodes {
          data {
            Region
            Enough
            LackVariety
            Sometimes
            NotEnough
            FoodManufacturingEmploymentRate
            FoodHospitalityEmploymentRate
          }
        }
      }
      allDroughtArmaResults {
        nodes {
          data {
            p
            d
            q
            AIC
            BIC
            AICc
          }
        }
      }
      allDroughtModelResults {
        nodes {
          data {
            Model
            MAE
            MSE
          }
        }
      }
      allFloodArmaResults {
        nodes {
          data {
            p
            d
            q
            AIC
            BIC
            AICc
          }
        }
      }
      allFloodModelResults {
        nodes {
          data {
            Model
            MAE
            MSE
          }
        }
      }
      allPriceArmaResults {
        nodes {
          data {
            p
            d
            q
            AIC
            BIC
            AICc
          }
        }
      }
      allPriceArchResults {
        nodes {
          data {
            p
            q
            AIC
          }
        }
      }
      allWeatherArmaResults {
        nodes {
          data {
            p
            d
            q
            P
            D
            Q
            AIC
            BIC
            AICc
          }
        }
      }
      allWeatherModelResults {
        nodes {
          data {
            Model
            MAE
            MSE
          }
        }
      }
      allWildfireArmaResults {
        nodes {
          data {
            p
            d
            q
            P
            D
            Q
            AIC
            BIC
            AICc
          }
        }
      }
      allWildfireModelResults {
        nodes {
          data {
            Model
            MAE
            MSE
          }
        }
      }
      allNnErrorResults {
        nodes {
          data {
            model
            training_rmse
            testing_rmse
          }
        }
      }
      allEarthquakeVariables {
        nodes {
          data {
            _Variable
            Description
          }
        }
      }
      allEarthquakePcaResults {
        nodes {
          data {
            _PC1
            PC2
            PC3
            PC4
            PC5
          }
        }
      }
      allEarthquakeConditionalProbabilities {
        nodes {
          data {
            _id
            damage_1
            damage_2
            damage_3
            geo1_dam1_prob
            geo1_dam2_prob
            geo1_dam3_prob
          }
        }
      }
    }
  `);

  return (
    <Accordion p={4} mb={3} allowToggle defaultIndex={[!!isOpen ? 0 : -1]}>
      <AccordionItem>
        <Flex>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              {title}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <chakra.a
            href={map.url(data)}
            m={'7px'}
            cursor={'pointer'}
            _hover={{
              color: 'blue'
            }}
          >
            <DownloadIcon />
          </chakra.a>
        </Flex>
        <AccordionPanel pb={4}>
          <GenericTable data={map.data(data)} columns={columns} />
        </AccordionPanel>
      </AccordionItem>

      {!!children ? (
        <Center
          style={{ color: 'black', fontSize: '14px', margin: '0 50px', fontStyle: 'italic', textAlign: 'center' }}
        >
          {children}
        </Center>
      ) : (
        <></>
      )}
    </Accordion>
  );
};

export default TableFactory;
