import ChartFactory from "../../../../src/components/charts/chart-factory.js";
import TableFactory from "../../../../src/components/tables/table-factory.js";
import * as d3 from 'd3';
import react from 'react';
import * as React from 'react';
export default {
  ChartFactory,
  TableFactory,
  d3,
  react,
  React
};