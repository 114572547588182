import { Center, Flex, Box, Spacer } from '@chakra-ui/react';
import ImageVisualizer from "../../../../src/components/image-visualizer.js";
import TabbedDataVisualizer from "../../../../src/components/tabbed-data-visualizer.js";
import * as React from 'react';
export default {
  Center,
  Flex,
  Box,
  Spacer,
  ImageVisualizer,
  TabbedDataVisualizer,
  React
};