import { Center } from '@chakra-ui/react';
import TableFactory from "../../../../src/components/tables/table-factory.js";
import DataVisualizer from "../../../../src/components/data-visualizer.js";
import ImageVisualizer from "../../../../src/components/image-visualizer.js";
import TabbedDataVisualizer from "../../../../src/components/tabbed-data-visualizer.js";
import * as React from 'react';
export default {
  Center,
  TableFactory,
  DataVisualizer,
  ImageVisualizer,
  TabbedDataVisualizer,
  React
};