import React from 'react';
import {
  Flex,
  chakra,
  Center,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react';
import { FaExpand } from 'react-icons/fa';

const ResponsiveImage = ({ src, alt, width }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex justifyContent="space-between"></Flex>
      <chakra.a
        onClick={onOpen}
        display="flex"
        float="right"
        cursor={'pointer'}
        _hover={{
          color: 'blue'
        }}
      >
        <chakra.span ml={'10px'} mt={'20px'}>
          <FaExpand />
        </chakra.span>
      </chakra.a>
      {!!src ? (
        <Center margin="20px">
          <chakra.a onClick={onOpen} cursor={'pointer'}>
            <img src={src} alt={alt} width={width} onClick={onOpen} />
          </chakra.a>
        </Center>
      ) : (
        <div> No Preview Exists Yet</div>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent minWidth={'60vw'}>
          <ModalCloseButton />
          <ModalBody>
            <img src={src} alt={alt} width="100%" height="100%" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ResponsiveImage;
