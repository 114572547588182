import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from './layout';
import { Box, Flex } from '@chakra-ui/react';
import Menu from './menu';
import ProjectBreadcrumb from './project-breadcrumb';

const ProjectLayout = ({ pageTitle, children, location }) => {
  return (
    <Layout>
      <Flex height="88vh" marginTop="2vh">
        <Menu pageTitle={pageTitle} location={location}></Menu>
        <Box
          flex="1"
          m="0.5rem 1rem 1rem 2rem"
          padding="3rem"
          overflowY="scroll"
          css={{
            '&::-webkit-scrollbar': {
              width: '6px'
            },
            '&::-webkit-scrollbar-track': {
              width: '8px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#CBD5E0',
              borderRadius: '24px'
            }
          }}
        >
          <ProjectBreadcrumb location={location} />
          {children}
        </Box>
      </Flex>
    </Layout>
  );
};

export default ProjectLayout;
