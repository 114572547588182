import * as React from 'react';
import { Box, Center, Stack, Text, chakra } from '@chakra-ui/react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';

const MenuItem = styled((props) => <Link {...props} />)`
  margin: 0.5px 10px 0px 10px !important;
  padding: 7px 0px 7px 0px;
  border-left: 1px solid transparent;
  text-decoration: none;
  color: #4a5568;
  :hover {
    color: #3182ce;
    cursor: pointer;
    border-left: 1px solid #2f4f4f;
  }
`;

const ActiveMenuItem = styled((props) => <MenuItem {...props} />)`
  color: #2c5282;
  border-left: 2px solid black;
`;

const MenuLink = styled.div`
  margin-left: 18px;
`;

const Menu = ({ pageTitle, children, location }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          projects {
            food {
              title
              image
              sections {
                name
                link
              }
            }
            admissions {
              title
              image
              sections {
                name
                link
              }
            }
            weather {
              title
              image
              sections {
                name
                link
              }
            }
            crashes {
              title
              image
              sections {
                name
                link
              }
            }
            contracts {
              title
              image
              sections {
                name
                link
              }
            }
            earthquakes {
              title
              image
              sections {
                name
                link
              }
            }
          }
        }
      }
    }
  `);

  const project = data.site.siteMetadata.projects[location.pathname.split('/')[1]];

  return (
    <Box h="85vh" w="250px">
      <Stack color="gray.400">
        <Center>
          <chakra.img src={project.image} alt={project.title} borderRadius="50%" width={100} height={100} />
        </Center>
        <Center>
          <Text mb="20px" color="#062044" textAlign="center">
            {pageTitle || project.title}
          </Text>
        </Center>
        {project.sections.map((item, index) => {
          if (location.pathname.startsWith(item.link)) {
            return (
              <ActiveMenuItem to={item.link} key={`link-${index}`}>
                <MenuLink>{item.name}</MenuLink>
              </ActiveMenuItem>
            );
          } else {
            return (
              <MenuItem to={item.link} key={`link-${index}`}>
                <MenuLink>{item.name}</MenuLink>
              </MenuItem>
            );
          }
        })}
      </Stack>
    </Box>
  );
};

export default Menu;
