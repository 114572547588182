import * as React from 'react';
const FancyImage = ({ title, description, imageLink, link }) => {
  return (
    <div className="view view-first">
      <img src={imageLink} alt="preview" />
      <div className="mask">
        <h2>{title}</h2>
        <p>{description}</p>
        <a href={link} target="_blank" rel="noopener noreferrer" className="info">
          Learn More
        </a>
      </div>
    </div>
  );
};

export default FancyImage;
