import * as React from 'react';
import ProjectLayout from '../components/project-layout';

const AdmissionsMainPage = ({ location }) => {
  return (
    <ProjectLayout pageTitle="College Admissions vs Graduation Rate" location={location}>
      <div>
        Hi, welcome to our project on the effect of different college statistics on graduation rates. Please click on
        the Introduction to get started.
      </div>
    </ProjectLayout>
  );
};

export default AdmissionsMainPage;
