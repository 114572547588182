import TabbedDataVisualizer from "../../../../src/components/tabbed-data-visualizer.js";
import DataVisualizer from "../../../../src/components/data-visualizer.js";
import { Center, Spacer } from '@chakra-ui/react';
import * as React from 'react';
export default {
  TabbedDataVisualizer,
  DataVisualizer,
  Center,
  Spacer,
  React
};