import * as React from 'react';
import Layout from '../components/layout';
import { Flex, Heading, IconButton, Text, Stat, StatLabel, StatNumber, StatGroup, Box } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import { FaLink } from 'react-icons/fa';

const AboutPage = () => {
  return (
    <Layout>
      <Flex flexDirection="row" margin="3rem">
        <Flex>
          <StaticImage
            src="../images/ProfilePicture.png"
            alt="Profile picture"
            style={{
              minWidth: '250px',
              maxWidth: '250px',
              minHeight: '250px',
              maxHeight: '250px'
            }}
            imgStyle={{ borderRadius: '50%' }}
          />
        </Flex>
        <Flex flexDirection="column" margin="2rem">
          <Heading>
            <IconButton
              marginRight="1rem"
              as="a"
              title="Personal Website"
              href="https://www.sampastoriza.com"
              aria-label="Personal Website"
              icon={<FaLink fontSize="20px" />}
            />
            Sam Pastoriza
          </Heading>
          <Text fontSize="1.25rem" color="gray.500" marginTop="0.25rem">
            Data Scientist
          </Text>
          <Text marginTop="0.5rem">
            Graduate Student of the Georgetown Data Science and Analytics Program with a background in commercial web
            application development. Proficient in a number of languages including Javascript, C# and Python and
            motivated to learn R. Data science interests include Machine Learning, Big Data, and Data Visualizations.
            Want to learn more? Click on the link to my personal website above! Thanks for visiting!
          </Text>
          <Box margin="10px" padding="10px">
            <StatGroup>
              <Stat>
                <StatLabel>Georgetown Id</StatLabel>
                <StatNumber>sjp114</StatNumber>
              </Stat>
            </StatGroup>
          </Box>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default AboutPage;
