import * as React from 'react';
import { Box, Flex, Heading, Center } from '@chakra-ui/react';
import CyberSecurityImage from '../images/cybersecurity.jpeg';
import CarAccidentImage from '../images/car-accident.jpeg';
import DroughtImage from '../images/drought.jpeg';
import AcademicImage from '../images/academic-background.jpeg';
import FoodImage from '../images/food-background.jpg';
import EarthquakeImage from '../images/earthquake-damage.jpeg';

const projects = [
  {
    title: 'Cyber Risk Management and Prioritization',
    link: '/contracts/introduction',
    image: CyberSecurityImage,
    imageAlt: 'Cybersecurity',
    description:
      'Using a wide variety of visualizations, we uncover the network of subcontracts to focus efforts on mitigating cybersecurity risks.'
  },
  {
    title: 'Insights on Fatal Car Accidents',
    link: '/crashes/understanding-the-data',
    image: CarAccidentImage,
    imageAlt: 'Car Accident',
    description:
      'A visual analysis of the factors of fatal car accidents from 1975-2019. This was a project done during the Georgetown Data Science Masters Program in collaberation with Haley Roberts, Monroe Farris, and Matthew Brennan.'
  },
  {
    title: 'Predicting Levels of Earthquake Damage',
    link: '/earthquakes/introduction',
    image: EarthquakeImage,
    imageAlt: 'Earthquake Damage',
    description:
      'A set of classification models are used to attempt to predict levels of earthquake damage to buildings in the Nepal region. This was a project done during the Georgetown Data Science Masters Program in collaberation with Haley Roberts, Hyuksoo Shin, and Harrison Lee.'
  },
  {
    title: 'The Effect of Climate Change',
    link: '/weather/introduction',
    image: DroughtImage,
    imageAlt: 'Drought',
    description:
      'This is a time series project learning and predicting the effect of climate change in the United States. This project concerns the large scale disasters that seem to occur more frequently due to climate change including floods, wildfires, and drought.'
  },
  {
    title: 'College Statistics vs Graduation Rate',
    link: '/admissions/introduction',
    image: AcademicImage,
    imageAlt: 'Academic Success',
    description:
      'An analysis of different college statistics vs graduation rates for 4 year institutions. This was a project done during the Georgetown Data Science Masters Program in collaberation with Haley Roberts and Monroe Farris.'
  },
  {
    title: 'The effect of Covid19 on the food supply',
    link: '/food/introduction',
    image: FoodImage,
    imageAlt: 'Assortment of Food',
    description:
      'An analysis of the effect of Covid19 on the global food supply using varieties of data science techniques. This was a project done during the Georgetown Data Science Masters Program.'
  }
];

const Projects = ({ data }) => {
  return (
    <Flex flexDirection="column" h="90vh" backgroundColor="white">
      <Center>
        <Heading marginTop="1rem" marginBottom="3rem">
          My Data Science Project Portfolio
        </Heading>
      </Center>
      <Flex flexWrap={'wrap'}>
        {projects.map((project) => {
          return (
            <Box maxW="sm" borderRadius="lg" overflow="hidden" background="transparent" m="2" key={project.link}>
              <a href={project.link}>
                <img alt={project.imageAlt} src={project.image} />
              </a>
              <Box p="6">
                <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight">
                  <a href={project.link}>{project.title}</a>
                </Box>
                <Box mt="1" lineHeight="tight">
                  {project.description}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default Projects;
