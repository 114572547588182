import { useD3 } from '../../hooks/useD3';
import React from 'react';
import * as d3 from 'd3';

const height = 500;
const width = 700;
const margin = { top: 50, right: 50, bottom: 50, left: 70 };

const updateChart = (svg, data, labels) => {
  const { yScale, xScale } = updateScales(data);
  const line = createLine(xScale, yScale);
  updateAxes(svg, data, xScale, yScale);
  addTitleAndAxisLabels(svg, labels);
  updatePath(svg, data, line);
};

const updateScales = (data) => {
  const xScale = d3
    .scaleTime()
    .domain(d3.extent(data, (d) => d.xValue))
    .range([0, width]);

  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max(data, (d) => d.yValue)])
    .range([height, 0]);

  return { xScale, yScale };
};

const createLine = (xScale, yScale) => {
  return d3
    .line()
    .x((d) => xScale(d.xValue))
    .y((d) => yScale(d.yValue));
};

const updateAxes = (svg, data, xScale, yScale) => {
  svg.select('.x-axis').attr('transform', `translate(${margin.left},${height})`).call(d3.axisBottom(xScale));
  svg
    .select('.y-axis')
    .attr('transform', `translate(${margin.left},0)`)
    .style('color', 'steelblue')
    .call(d3.axisLeft(yScale).ticks(null, 's'));
};

const addTitleAndAxisLabels = (svg, { title, xLabel, yLabel }) => {
  svg
    .append('text')
    .attr('transform', `translate(${margin.left + width / 2}, 20)`)
    .style('text-anchor', 'middle')
    .text(title);

  svg
    .append('text')
    .attr('transform', `translate(${margin.left + width / 2}, ${height + margin.bottom})`)
    .style('text-anchor', 'middle')
    .text(xLabel);

  svg
    .append('text')
    .attr('transform', 'rotate(-90)')
    .attr('x', 0 - height / 2)
    .attr('y', 0)
    .attr('dy', '1em')
    .style('text-anchor', 'middle')
    .text(yLabel);
};

const updatePath = (svg, data, line) => {
  const updatedPath = svg
    .select('.plot-area')
    .append('path')
    .interrupt()
    .datum(data)
    .attr('fill', 'none')
    .attr('stroke', 'steelblue')
    .attr('stroke-width', 1.5)
    .attr('stroke-linejoin', 'round')
    .attr('stroke-linecap', 'round')
    .attr('transform', `translate(${margin.left},0)`)
    .attr('d', line);

  const pathLength = updatedPath.node().getTotalLength();
  const transitionPath = d3.transition().ease(d3.easeSin).duration(2500);

  updatedPath
    .attr('stroke-dashoffset', pathLength)
    .attr('stroke-dasharray', pathLength)
    .transition(transitionPath)
    .attr('stroke-dashoffset', 0);
};

const LineChart = ({ labels, data, map }) => {
  const cleanedData = data
    .map((item) => ({
      xValue: map.xValues(item),
      yValue: map.yValues(item)
    }))
    .sort((a, b) => a.xValue - b.xValue);

  const ref = useD3(
    (svg) => {
      updateChart(svg, cleanedData, labels);
    },
    [cleanedData.length]
  );

  return (
    <svg
      ref={ref}
      style={{
        height: 600,
        width: '100%',
        marginRight: '0px',
        marginLeft: '0px'
      }}
    >
      <g className="plot-area" />
      <g className="x-axis" />
      <g className="y-axis" />
    </svg>
  );
};

export default LineChart;
