import React from 'react';
import { Select, Box } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';

const SelectableDataVisualizer = ({ visualizationType, placeholderText }) => {
  const data = useStaticQuery(graphql`
    query {
      allGlobalCovidCaseVisualizations {
        nodes {
          data {
            s3Url
            display
          }
        }
      }
      allUsCovidCaseVisualizations {
        nodes {
          data {
            s3Url
            display
          }
        }
      }
      allConsumerPriceIndicesFoodIndices {
        nodes {
          data {
            s3Url
            display
          }
        }
      }
      allConsumerPriceIndicesFoodPriceInflation {
        nodes {
          data {
            s3Url
            display
          }
        }
      }
      allConsumerPriceIndicesGeneralIndices {
        nodes {
          data {
            s3Url
            display
          }
        }
      }
      allFoodSecurityIndicatorsFoodInsecurityPercent {
        nodes {
          data {
            s3Url
            display
          }
        }
      }
      allFoodSecurityIndicatorsFoodSupplyVariability {
        nodes {
          data {
            s3Url
            display
          }
        }
      }
      allFoodAndHospitalityEmployment {
        nodes {
          data {
            s3Url
            display
          }
        }
      }
      allFoodManufacturingEmployment {
        nodes {
          data {
            s3Url
            display
          }
        }
      }
      allSearchResults {
        nodes {
          data {
            s3Url
            display
          }
        }
      }
      allLockdownData {
        nodes {
          data {
            s3Url
            display
          }
        }
      }
      allHouseholdSurveysChildren {
        nodes {
          data {
            s3Url
            display
          }
        }
      }
      allHouseholdSurveysAll {
        nodes {
          data {
            s3Url
            display
          }
        }
      }
    }
  `);

  const iframeRef = React.createRef();
  const iframeId = Math.floor(Math.random() * 1000000 + 1);

  const handleChange = (event) => {
    iframeRef.current.src = event.target.value;
    iframeRef.current.height = event.target.value ? '700' : '';
  };

  return (
    <>
      <Select placeholder={placeholderText} variant="flushed" onChange={handleChange}>
        {data[visualizationType].nodes[0].data.map((item) => {
          return (
            <option key={item.s3Url} value={item.s3Url}>
              {item.display}
            </option>
          );
        })}
      </Select>
      <Box margin="20px">
        <iframe
          id={iframeId + ''}
          ref={iframeRef}
          title="selectable-graph"
          scrolling="no"
          style={{ border: 'none' }}
          seamless="seamless"
          src=""
          height="0"
          width="100%"
        ></iframe>
      </Box>
    </>
  );
};

export default SelectableDataVisualizer;
