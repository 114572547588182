import * as React from 'react';
import ProjectLayout from '../components/project-layout';

const CrashesMainPage = ({ location }) => {
  return (
    <ProjectLayout pageTitle="Insights on Factors of Fatal Car Crashes" location={location}>
      <div>
        Hi, welcome to our project on understanding the factors of fatal car crashes using modern visualization
        techniques. Click on Understanding the Data to get started.
      </div>
    </ProjectLayout>
  );
};

export default CrashesMainPage;
