import TabbedDataVisualizer from "../../../../src/components/tabbed-data-visualizer.js";
import TableFactory from "../../../../src/components/tables/table-factory.js";
import ImageVisualizer from "../../../../src/components/image-visualizer.js";
import { Center } from '@chakra-ui/react';
import * as React from 'react';
export default {
  TabbedDataVisualizer,
  TableFactory,
  ImageVisualizer,
  Center,
  React
};